import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import Content from "../components/content"
import ShareLinks from "../components/ShareLinks"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

function slugify(text) {
  const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
  const to = "aaaaaeeeeeiiiiooooouuuunc------"

  const newText = text.split('').map(
    (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))

  return newText
    .toString()                     // Cast to string
    .toLowerCase()                  // Convert the string to lowercase letters
    .normalize('NFD')               // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim()                         // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')           // Replace spaces with -
    .replace(/&/g, '-y-')           // Replace & with 'and'
    .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
    .replace(/\-\-+/g, '-');        // Replace multiple - with single -
}

const BlogPost = ({data, location}) => {
    const post = data.graphAPI.blogPost
    let baseurl = `https://www.fbmud162.com`
    const languages = data.site.siteMetadata.postlanguages

    

    const LocationNav = () => {
        if(post.localizations.length !== 0) {
            // has localization
            return (
                <div className="lang-select">
                    {/* <p>Select Language</p> */}
                    <div className="locale-list">
                    {languages.langs.map(language => {
                        // Is this language published
                        let langExists = false;
                        if(post.locale === language.key) {
                            langExists = true;
                        } else {
                            post.localizations.forEach( locale => {
                                if(locale.locale === language.key) {
                                    langExists = true;
                                }
                            })
                        }

                        let langLink = '/'; // failure goes home

                        if(post.locale === languages.defaultLangKey) {
                            // post is default
                                let date = moment(post.publishedAt).format("YYYY-MM-DD")
                                let slug = slugify(post.title)

                            if(language.key === languages.defaultLangKey) {
                                langLink = `/posts/${date}/${slug}`
                            } else {
                                post.localizations.forEach(locale => {
                                    if(language.key === locale.locale) {
                                        langLink = `/${language.key}/posts/${date}/${slug}`
                                    }
                                })
                            }
                        } else {
                            // post is not default
                            let primarySlug = '/'; // failure goes home
                            post.localizations.forEach(locale => {
                                if(locale.locale === languages.defaultLangKey) {
                                    let date = moment(locale.publishedAt).format("YYYY-MM-DD")
                                    let slug = slugify(locale.title)
                                    primarySlug = `posts/${date}/${slug}`
                                }
                            })

                            if(language.key === post.locale) {
                                langLink = `/${post.locale}/${primarySlug}`
                            } else {
                                post.localizations.forEach(locale => {
                                    if(language.key === locale.locale) {
                                        if(locale.locale === languages.defaultLangKey) {
                                            langLink = `/${primarySlug}`
                                        } else {
                                            langLink = `/${locale.locale}/${primarySlug}`
                                        }
                                    }
                                })
                            }
                            

                        }
                        if(langExists) {
                            return (<Link to={langLink} className={`button is-small ${(language.key === post.locale) ? 'is-primary' : ''} locale-item`}>{language.label}</Link>);
                        } else {
                            return (<></>)
                        }
                    })}
                    </div>
                    <hr />
                </div>);
        } else {
            return (<></>)
        }
    }

    return (
        <Layout>
            <SEO title={post.title} meta={[
                { name: `og:url`,
                content: `${baseurl}${location.pathname}` },
                { name: `og:type`,
                content: `article` },
                { name: `og:title`,
                content: `${post.title}` },
                { name: `og:description`,
                content: `${post.description}` },
                { name: `og:image`,
                content: `${baseurl}${post.heroImage.documentFile.childImageSharp.resize.src}` },
            ]}/>
                    <LocationNav />
                    <div className="title-section">
                        <h1 className="title is-size-1">{post.title}</h1>
                        <small style={{display: 'block', marginTop: '-1rem'}}>{moment(post.publishedAt).format("MMM D, YYYY")}</small>
                        <div class="dropdown is-hoverable" style={{marginTop: '2rem'}}>
                        <div class="dropdown-trigger">
                            <button class="button is-primary" aria-haspopup="true" aria-controls="dropdown-menu4">
                            <span>Share</span>
                            <span className="icon is-small">
                                <FontAwesomeIcon icon={faAngleDown} />
                            </span>
                            </button>
                        </div>
                        <div class="dropdown-menu" id="dropdown-menu4" role="menu">
                            <div class="dropdown-content">
                            <ShareLinks url={`${baseurl}${location.pathname}`} />
                            </div>
                        </div>
                        </div>
                    </div>
                    <Content content={post.content} />
        </Layout>
    )
}

export default BlogPost

export const pageQuery = graphql`
    query BlogPostByID($id: ID!) {
        site {
            siteMetadata {
                postlanguages {
                    langs {
                        key
                        label
                    }
                    defaultLangKey
                }
            }
        }
        graphAPI {
            blogPost(id: $id) {
                id
                title
                description
                locale
                localizations {
                    id
                    locale
                    title
                    publishedAt
                }
                heroImage {
                    id
                    url
                    updated_at
                    documentFile {
                        childImageSharp {
                            resize(width: 1024){
                                src
                            }
                        }
                    }
                }
                publishedAt
                content {
                    ... on GRAPHAPI_ComponentPageBaseContent {
                        ...baseContent
                    }
                    ... on GRAPHAPI_ComponentPageImage {
                        ...pageImage
                    }
                    ... on GRAPHAPI_ComponentPageLinkCardWithImage {
                        ...linkCardWithImage
                    }
                    ... on GRAPHAPI_ComponentPageSplitContentImage {
                        ...splitContentImage
                    }
                    ... on GRAPHAPI_ComponentPageGallery {
                        ...pageGallery
                    }
                    ... on GRAPHAPI_ComponentPageLinkList {
                        ...pageLinkList
                    }

                }
            }
        } 
    }
`


